import React from 'react';
import FeaturedInfo from '../components/FeaturedInfo';
import FeaturedInfoRules from '../components/FeaturedInfoRules';

const players6 = [
  { id: 1, week: '6', game: 'Jaguars @ Bears', bet: 'Bears -1', result: 'TBD' },
  { id: 2, week: '6', game: 'Lions @ Cowboys', bet: 'Lions -3.5', result: 'TBD' },
];

const players5 = [
  { id: 1, week: '5', game: 'Ravens @ Bengals', bet: 'Ravens -2.5', result: 'Win' },
  { id: 2, week: '5', game: 'Bills @ Texans', bet: 'Bills +1', result: 'Loss' },
  { id: 3, week: '5', game: 'Packers @ Rams', bet: 'Packers -3', result: 'Win' },
  { id: 4, week: '5', game: 'Saints @ Chiefs', bet: 'Over 43', result: 'Loss' },
];

const players4 = [
  { id: 1, week: '4', game: 'Browns @ Raiders', bet: 'Browns -2.5 && Over 36.6', result: 'Loss' },
  { id: 2, week: '4', game: 'Saints @ Falcons', bet: 'Over 41.5', result: 'Win' },
  { id: 3, week: '4', game: 'Cardinals @ Commanders', bet: 'Cardinals -3.5', result: 'Loss' },
  { id: 4, week: '4', game: 'Seahawks @ Lions', bet: 'Lions -3.5', result: 'Win' },
];

const players3 = [
  { id: 1, week: '3', game: 'Texans @ Vikings', bet: 'Vikings ML', result: 'Win' },
  { id: 2, week: '3', game: 'Bears @ Colts', bet: 'Under 43.5', result: 'Win' },
];

const players2 = [
  { id: 1, week: '2', game: 'Saints @ Cowboys', bet: 'Over 46.5', result: 'Win' },
  { id: 2, week: '2', game: 'Vikings @ 49ers', bet: 'Over 45.5', result: 'Loss' },
  { id: 3, week: '2', game: 'Steelers @ Broncos', bet: 'Steelers -2.5', result: 'Win' },
];

const players = [
  { id: 1, week: '1', game: 'Ravens @ Chiefs', bet: 'Under 46.5', result: 'Loss' },
  { id: 2, week: '1', game: 'Jaguras @ Dolphins', bet: 'Dolphins -3.5', result: 'Loss' },
  { id: 3, week: '1', game: 'Raiders @ Chargers', bet: 'Over 40', result: 'Loss' },
  { id: 4, week: '1', game: 'Cowboys @ Browns', bet: 'Cowboys -2 && Over 41', result: 'Win' },
];

const NFLStatsTable = () => (
  <>
  <div className="item3">
    <FeaturedInfoRules />
  </div>
  <br /><br />

  <div className="item3">
    <FeaturedInfo />
  </div>
  <br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players6.map(player6 => (
          <tr key={player6.id}>
            <td>{player6.week}</td>
            <td>{player6.game}</td>
            <td>{player6.bet}</td>
            <td className="td-result">{player6.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players5.map(player5 => (
          <tr key={player5.id}>
            <td>{player5.week}</td>
            <td>{player5.game}</td>
            <td>{player5.bet}</td>
            <td className="td-result">{player5.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players4.map(player4 => (
          <tr key={player4.id}>
            <td>{player4.week}</td>
            <td>{player4.game}</td>
            <td>{player4.bet}</td>
            <td className="td-result">{player4.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players3.map(player3 => (
          <tr key={player3.id}>
            <td>{player3.week}</td>
            <td>{player3.game}</td>
            <td>{player3.bet}</td>
            <td className="td-result">{player3.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />

  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players2.map(player2 => (
          <tr key={player2.id}>
            <td>{player2.week}</td>
            <td>{player2.game}</td>
            <td>{player2.bet}</td>
            <td className="td-result">{player2.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
<br /><br />
  <div className="table-container">
    <table className="styled-table">
      <thead>
        <tr>
          <th>Week</th>
          <th>Game</th>
          <th>Bet</th>
          <th>Result</th>
        </tr>
      </thead>
      <tbody>
        {players.map(player => (
          <tr key={player.id}>
            <td>{player.week}</td>
            <td>{player.game}</td>
            <td>{player.bet}</td>
            <td className="td-result">{player.result}</td>
          </tr>
        ))}
      </tbody>
    </table>
    <br /><br />
  </div>
  </>
);

export default NFLStatsTable;
